import React, { useState, useMemo, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Container,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Flex,
  Button,
  useDisclosure,
  IconButton,
  Input,
  Link,
} from "@chakra-ui/react";
import { FiEdit, FiTrash, FiUserPlus } from "react-icons/fi";
import { useQuery, gql } from "urql";
import {
  useTable,
  CellProps,
  Column,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { ContentChannelsQuery, ContentChannel } from "../graphql-types";
import ChannelCreateModal from "../components/ChannelModal";
import ChannelDeleteModal from "../components/ChannelDeleteModal";
import ChannelInviteModal from "../components/ChannelInviteModal";
import TablePagination from "../components/TablePagination";

const ContentChannels = gql`
  query ContentChannels {
    contentChannelsAll {
      __typename
      id
      name
    }
  }
`;

const Channels: React.FC = () => {
  const [{ data: contentChannelData, fetching }] =
    useQuery<ContentChannelsQuery>({
      query: ContentChannels,
    });

  const [channel, setChannel] = useState<Partial<ContentChannel> | null>(null);

  const {
    onOpen: handleInviteModalOpen,
    onClose: handleInviteModelClose,
    isOpen: isInviteModelOpen,
  } = useDisclosure();

  const {
    isOpen: isCreateChannelModalOpen,
    onClose: onCreateChannelModalClose,
    onOpen: onCreateChannelModalOpen,
  } = useDisclosure();

  const {
    isOpen: isDeleteChannelOpen,
    onOpen: onDeleteChannelOpen,
    onClose: onDeleteChannelClose,
  } = useDisclosure();

  const handleEdit = useCallback(
    (channel: Partial<ContentChannel>) => () => {
      const url = `${process.env.REACT_APP_WEB_URL}/channel/${channel.id}`;
      window.open(url, "_blank")!.focus();
    },
    []
  );

  const handleDelete = useCallback(
    (channel: Partial<ContentChannel>) => () => {
      onDeleteChannelOpen();
      setChannel(channel);
    },
    [onDeleteChannelOpen, setChannel]
  );

  const handleStopDelete = useCallback(() => {
    setChannel(null);
    onDeleteChannelClose();
  }, [onDeleteChannelClose, setChannel]);

  const handleInviteToChannel = useCallback(
    (channel: Partial<ContentChannel>) => () => {
      setChannel(channel);
      handleInviteModalOpen();
    },
    [setChannel, handleInviteModalOpen]
  );

  const handleStopInviteToChannel = () => {
    setChannel(null);
    handleInviteModelClose();
  };

  const data = useMemo(
    () =>
      contentChannelData && contentChannelData.contentChannelsAll
        ? contentChannelData.contentChannelsAll
        : [],
    [contentChannelData]
  );

  const columns = useMemo(
    (): Column<Partial<ContentChannel>>[] => [
      {
        Header: "Name",
        accessor: "name" as "name",
        Cell: ({ value, row }: CellProps<Partial<ContentChannel>>) => (
          <Link
            as={RouterLink}
            to={`/channels/${row.original.id ?? ""}`}
            color={"brand.600"}
          >
            {value}
          </Link>
        ),
      },
      {
        id: "edit",
        Header: "Edit",
        Cell: ({ row }: CellProps<Partial<ContentChannel>>) => (
          <IconButton
            aria-label="Edit Learning Center"
            icon={<FiEdit />}
            onClick={handleEdit(row.original)}
          />
        ),
      },
      {
        id: "invite",
        Header: "Add Admin",
        Cell: ({ row }: CellProps<Partial<ContentChannel>>) => (
          <IconButton
            ml={4}
            aria-label="Add Admin"
            icon={<FiUserPlus />}
            onClick={handleInviteToChannel(row.original)}
          />
        ),
      },
      {
        id: "delete",
        Header: "Delete",
        Cell: ({ row }: CellProps<Partial<ContentChannel>>) => (
          <IconButton
            aria-label="Delete Channel"
            icon={<FiTrash />}
            onClick={handleDelete(row.original)}
          />
        ),
      },
    ],
    [handleEdit, handleInviteToChannel, handleDelete]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    page,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable({ columns, data }, useGlobalFilter, usePagination);

  if (fetching) {
    return <Spinner />;
  }

  return (
    <Container centerContent maxW="container.lg" bg="white" py="8">
      <Heading>Channels</Heading>
      <Input
        mx={6}
        mt={4}
        placeholder="Search"
        onChange={(e) => setGlobalFilter(e.target.value)}
        value={globalFilter}
      />
      <Table variant="simple" {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {data.length ? (
        <TablePagination
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          totalCount={pageOptions.length}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
        />
      ) : null}
      <Flex justify="space-between" width="100%" px={2} py={4}>
        <Button onClick={onCreateChannelModalOpen}>
          Create Content Channel
        </Button>
        <Button onClick={handleInviteModalOpen}>
          Setup Content Channel By New User
        </Button>
      </Flex>
      <ChannelCreateModal
        isOpen={isCreateChannelModalOpen}
        onClose={onCreateChannelModalClose}
      />
      <ChannelDeleteModal
        channel={channel}
        onClose={handleStopDelete}
        isOpen={isDeleteChannelOpen}
      />
      <ChannelInviteModal
        onClose={handleStopInviteToChannel}
        isOpen={isInviteModelOpen}
        channel={channel}
      />
    </Container>
  );
};

export default Channels;
