import React, { useEffect, useCallback, useState } from "react";
import {
  Container,
  Box,
  Heading,
  VStack,
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Button,
  useToast,
  Spinner,
  InputGroup,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, gql } from "urql";
import useUser from "../hooks/useUser";

const UpdateUserMutation = gql`
  mutation UpdateUserMutation(
    $userId: UserId!
    $data: UpdateUserInput!
    $picture: Upload
  ) {
    updateUser(id: $userId, data: $data, picture: $picture) {
      id
      firstName
      lastName
    }
  }
`;

export type UserData = {
  firstName: string;
  lastName: string;
};

const Profile: React.FC = () => {
  const [{ data: userData, fetching }] = useUser();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<UserData>();

  useEffect(() => {
    if (userData?.user) {
      reset({
        firstName: userData.user.firstName || "",
        lastName: userData.user.lastName || "",
      });
    }
  }, [reset, userData?.user]);

  const [, updateUser] = useMutation(UpdateUserMutation);
  const [picture, setPicture] = useState<undefined | File>();

  const onSubmit = useCallback(
    async (data: UserData) => {
      const response = await updateUser({
        userId: { id: userData?.user.id },
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
        },
        picture: picture,
      });

      if (response.error) {
        toast({
          title: "Failed!",
          description: response.error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "User Updated!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [updateUser, userData, toast, picture]
  );

  if (fetching) {
    <Spinner />;
  }

  return (
    <Container centerContent maxW="container.lg" bg="white" py="8">
      <Heading>Profile</Heading>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <VStack>
          <FormControl isInvalid={!!errors.firstName}>
            <FormLabel htmlFor="firstName">First name</FormLabel>
            <Input
              id="firstName"
              placeholder="First Name"
              {...register("firstName")}
            />
            <FormErrorMessage>
              {errors.firstName && errors.firstName.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.lastName}>
            <FormLabel htmlFor="lastName">Last Name</FormLabel>
            <Input
              id="lastName"
              placeholder="Last Name"
              {...register("lastName")}
            />
            <FormErrorMessage>
              {errors.lastName && errors.lastName.message}
            </FormErrorMessage>
          </FormControl>
          <InputGroup>
            <input
              type="file"
              onChange={(event) =>
                setPicture(
                  event.target.files ? event.target.files[0] : undefined
                )
              }
            />
          </InputGroup>
        </VStack>
        <Button
          type="submit"
          isLoading={isSubmitting}
          my={2}
          colorScheme="brand"
        >
          Update
        </Button>
      </Box>
    </Container>
  );
};

export default Profile;
