import React, { useCallback } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { gql, useMutation } from "urql";
import {
  ContentChannel,
  DeleteContentChannelMutation,
  DeleteContentChannelMutationVariables,
} from "../graphql-types";

const deleteContentChannelMutation = gql`
  mutation DeleteContentChannel($id: ID!) {
    deleteContentChannel(id: $id) {
      id
    }
  }
`;

export type ChannelDeleteModalProps = {
  onClose: () => void;
  channel: null | Partial<ContentChannel>;
  isOpen: boolean;
};

const ChannelDeleteModal: React.FC<ChannelDeleteModalProps> = ({
  onClose,
  channel,
  isOpen,
}) => {
  const toast = useToast();

  const [, deleteContentChannel] = useMutation<
    DeleteContentChannelMutation,
    DeleteContentChannelMutationVariables
  >(deleteContentChannelMutation);

  const onClick = useCallback(async () => {
    const response = await deleteContentChannel({
      id: channel!.id!,
    });
    if (response.error) {
      toast({
        title: "Failed!",
        description: response.error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Content Channel Deleted",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
    onClose();
  }, [channel, deleteContentChannel, toast, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Content Channel {channel?.name}?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>This operation cannot be undone.</ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="red" onClick={onClick}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChannelDeleteModal;
