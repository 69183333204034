import React, { useState, useCallback } from "react";
import {
  Spinner,
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
} from "@chakra-ui/react";
import SignIn, { SignInData } from "./SignIn";
import NewPassword, { NewPasswordData } from "./NewPassword";
import { signIn, completeNewPassword, signOut } from "../lib/auth";
import useAuth from "../hooks/useAuth";
import useUser from "../hooks/useUser";
import { UserRole } from "../graphql-types";

const Authenticator: React.FC = ({ children }) => {
  const { isAuthenticated, isAuthenticating, setUser } = useAuth();
  const [{ fetching: isFetchingDbUser, error: userError, data: userData }] =
    useUser();
  const [tempUser, setTempUser] = useState(null);
  const [error, setError] = useState<Error | null>(null);
  const clearError = useCallback(() => setError(null), [setError]);

  const handleSignIn = useCallback(
    async ({ email, password }: SignInData) => {
      try {
        const response = await signIn(email, password);
        if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
          setTempUser(response);
        } else {
          setUser(response);
        }
        return response;
      } catch (err) {
        console.error(err);
        setError(err as Error);
      }
    },
    [setUser, setTempUser]
  );

  const handleLogOut = useCallback(() => {
    signOut();
    setUser(null);
  }, [setUser]);

  const handleNewPassword = useCallback(
    async ({ password }: NewPasswordData) => {
      try {
        if (tempUser) {
          const response = await completeNewPassword(tempUser, password);
          setUser(response);
        }
      } catch (err) {
        console.error(err);
        setError(err as Error);
      }
    },
    [tempUser, setUser]
  );

  if (userError || userData?.user === null) {
    return (
      <Flex>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>User does not exist in the database</AlertTitle>
          <AlertDescription>
            Please contact administrator to have this error resolved
            {userError && <code>{userError.message}</code>}
          </AlertDescription>
        </Alert>
      </Flex>
    );
  }

  const isAdmin = userData?.user.roles.find(
    (uRole) => uRole.role === UserRole.Admin
  );

  if (isAuthenticated && !isFetchingDbUser && !isAdmin) {
    return (
      <Flex>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>No permission</AlertTitle>
          <AlertDescription>
            You don't have required permissions to see this app. Please contact
            your administrator to grant you required access rights.
          </AlertDescription>
          <Button colorScheme="red" onClick={handleLogOut} ml={2}>
            Log out
          </Button>
        </Alert>
      </Flex>
    );
  }

  if (isAuthenticated && !isFetchingDbUser) {
    return <>{children}</>;
  }

  if (isAuthenticating || isFetchingDbUser) {
    return (
      <Flex minH="100vh" alignItems="center" justifyContent="center">
        <Spinner color="brand.300" size="xl" />
      </Flex>
    );
  }

  if (tempUser) {
    return (
      <NewPassword
        error={error}
        onSubmit={handleNewPassword}
        onClearError={clearError}
      />
    );
  }

  return (
    <SignIn error={error} onSubmit={handleSignIn} onClearError={clearError} />
  );
};

export default Authenticator;
