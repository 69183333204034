import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  FormErrorMessage,
  Stack,
  Button,
  Heading,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import Card from "./Card";

export type SignInData = {
  email: string;
  password: string;
};

export type SignInProps = {
  error: null | Error;
  onSubmit: (data: SignInData) => void;
  onClearError: () => void;
};

const SignIn: React.FC<SignInProps> = ({ onSubmit, error, onClearError }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignInData>();

  return (
    <Card error={error} onClearError={onClearError}>
      <Stack spacing={4} align={"center"}>
        <Heading fontSize={"2xl"}>Sign in into your account</Heading>
      </Stack>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack py={6} spacing={4}>
          <FormControl isInvalid={!!errors.email}>
            <FormLabel key="email" htmlFor="email">
              Email address
            </FormLabel>
            <Input
              id="email"
              focusBorderColor="brand.500"
              type="email"
              {...register("email")}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.password}>
            <FormLabel key="password" htmlFor="password">
              Password
            </FormLabel>
            <Input
              id="password"
              focusBorderColor="brand.500"
              type="password"
              {...register("password")}
            />
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>
        </Stack>
        <Stack spacing={5}>
          <Stack
            direction={{ base: "column", sm: "row" }}
            align={"start"}
            justify={"space-between"}
          >
            <Checkbox colorScheme="brand">Remember me</Checkbox>
          </Stack>
          <Button
            marginTop={4}
            bg={"brand.400"}
            color={"white"}
            _hover={{
              bg: "brand.500",
            }}
            size="lg"
            w="200px"
            alignSelf="center"
            type="submit"
            isLoading={isSubmitting}
          >
            Sign in
          </Button>
        </Stack>
      </Box>
    </Card>
  );
};

export default SignIn;
