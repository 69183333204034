import React, { useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, gql } from "urql";
import { ContentChannel } from "../graphql-types";

const InviteToChannel = gql`
  mutation InviteToChannel(
    $recipientInput: InviteRecipientInput!
    $channelId: ID!
  ) {
    inviteContentChannelOwner(
      recipientInput: $recipientInput
      contentChannelId: $channelId
    ) {
      id
      status
      type
    }
  }
`;

const InviteNewChannelOwner = gql`
  mutation InviteNewChannelOwner($recipientInput: InviteRecipientInput!) {
    inviteNewContentChannelOwner(recipientInput: $recipientInput) {
      id
      status
      type
    }
  }
`;

export type UserInvitationData = {
  firstName: string;
  lastName: string;
  email: string;
};

export type ChannelEditModalProps = {
  onClose: () => void;
  isOpen: boolean;
  channel: Partial<ContentChannel> | null;
};

const ChannelInviteModal: React.FC<ChannelEditModalProps> = ({
  onClose,
  isOpen,
  channel,
}) => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UserInvitationData>();

  const [, inviteNewChannelOwner] = useMutation(InviteNewChannelOwner);

  const [, inviteToChannel] = useMutation(InviteToChannel);

  const onSubmit = useCallback(
    async ({ email, firstName, lastName }: UserInvitationData) => {
      let response;
      if (channel) {
        response = await inviteToChannel({
          recipientInput: {
            email,
            lastName,
            firstName,
          },
          channelId: channel.id,
        });
      } else {
        response = await inviteNewChannelOwner({
          recipientInput: { email, firstName, lastName },
        });
      }

      if (response.error) {
        toast({
          title: "Failed!",
          description: response.error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Invite Sent",
          description: `We've invited ${firstName} ${lastName}`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [channel, toast, inviteNewChannelOwner, inviteToChannel]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          {channel
            ? `Invite Admin to ${channel.name}`
            : "Invite New Admin to create a new Content Channel"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <FormControl isInvalid={!!errors.firstName}>
              <FormLabel htmlFor="firstName">First Name</FormLabel>
              <Input
                id="firstName"
                placeholder="First Name"
                {...register("firstName")}
              />
              <FormErrorMessage>
                {errors.firstName && errors.firstName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.lastName}>
              <FormLabel htmlFor="lastName">Last Name</FormLabel>
              <Input
                id="lastName"
                placeholder="Last Name"
                {...register("lastName")}
              />
              <FormErrorMessage>
                {errors.lastName && errors.lastName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.email}>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                type="email"
                placeholder="Email"
                {...register("email")}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="brand" type="submit" isLoading={isSubmitting}>
            Invite
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChannelInviteModal;
