import React from "react";
import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Button,
  Heading,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import Card from "./Card";
// import Logo from './Logo';

export type NewPasswordData = {
  password: string;
};

export type NewPaswordProps = {
  error: null | Error;
  onSubmit: (data: NewPasswordData) => void;
  onClearError: () => void;
};

const NewPassword: React.FC<NewPaswordProps> = ({
  onSubmit,
  error,
  onClearError,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<NewPasswordData>();

  return (
    <Card error={error} onClearError={onClearError}>
      <Stack spacing={4} align={"center"}>
        {/* <Logo /> */}
        <Heading fontSize={"2xl"}>New Password</Heading>
      </Stack>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack py={6} spacing={4}>
          <FormControl isInvalid={!!errors.password}>
            <FormLabel key="password" htmlFor="password">
              New Password
            </FormLabel>
            <Input
              id="password"
              focusBorderColor="brand.500"
              type="password"
              {...register("password")}
            />
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>
        </Stack>
        <Stack marginTop={4} spacing={4}>
          <Button
            bg={"brand.400"}
            color={"white"}
            _hover={{
              bg: "brand.500",
            }}
            size="lg"
            w="200px"
            alignSelf="center"
            type="submit"
            isLoading={isSubmitting}
          >
            Send
          </Button>
          <Text>
            Already have an account?{" "}
            <Link color={"brand.400"} href="/">
              Sign in
            </Link>
          </Text>
        </Stack>
      </Box>
    </Card>
  );
};

export default NewPassword;
