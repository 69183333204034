import React, { useCallback } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { gql, useMutation } from "urql";
import {
  DeleteContentChannelMutationVariables,
  DeleteCourseMutation,
} from "../graphql-types";

export type DeleteCourseData = {
  id: string;
  title: string;
};

const DeleteCourse = gql`
  mutation DeleteCourse($id: ID!) {
    deleteCourse(courseId: $id) {
      id
    }
  }
`;

export type CourseDeleteModalProps = {
  onClose: () => void;
  isOpen: boolean;
  course: DeleteCourseData | null;
};

const CourseDeleteModal: React.FC<CourseDeleteModalProps> = ({
  onClose,
  isOpen,
  course,
}) => {
  const toast = useToast();

  const [, deleteCourse] = useMutation<
    DeleteCourseMutation,
    DeleteContentChannelMutationVariables
  >(DeleteCourse);

  const onClick = useCallback(async () => {
    if (!course?.id) {
      return;
    }

    const response = await deleteCourse({
      id: course.id,
    });

    if (response.error) {
      toast({
        title: "Failed!",
        description: response.error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Course Deleted",
        description: `${course.title} was deleted`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    }
  }, [course, toast, deleteCourse, onClose]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Course {course?.title}?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{"This cannot be undone."}</ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="red" onClick={onClick}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CourseDeleteModal;
