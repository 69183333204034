import { useState, useEffect, useCallback } from "react";
import { useAsyncFn } from "react-use";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { useQuery, useMutation, gql } from "urql";
import axios from "axios";
import { useForm } from "react-hook-form";
import create from "zustand";
import {
  CreateCourseMutation,
  CreateCourseMutationVariables,
  CourseInput,
  UploadVideoMutation,
  UploadVideoMutationVariables,
  VideoUploadStatusQuery,
  VideoUploadStatusQueryVariables,
  ContentChannel,
  CourseStatus,
} from "../graphql-types";

const useStore = create<{
  progress: number;
  uploadFile: (url: string, video: File) => Promise<void>;
}>((set) => ({
  progress: 0,
  uploadFile: async (url: string, video: File) => {
    set((state) => ({ ...state, progress: 0 }));
    var bodyFormData = new FormData();
    bodyFormData.append("", video!);
    await axios.put(url, video, {
      onUploadProgress: (progressEvent) =>
        set((state) => ({
          ...state,
          progress: Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          ),
        })),
    });
  },
}));

export type CreateCourseModalProps = {
  isOpen: boolean;
  channel: Pick<ContentChannel, "id">;
  onClose: () => void;
};

const createCourseMutation = gql`
  mutation createCourse($channelId: ID!, $data: CourseInput!) {
    createCourse(channelId: $channelId, data: $data) {
      __typename
      id
      description
      video {
        ... on HostedVideo {
          id
          __typename
          status
          mp4Url
          hlsUrl
        }
      }
    }
  }
`;

const uploadVideoQuery = gql`
  mutation uploadVideo($courseId: ID!, $filename: String!) {
    uploadVideo(courseId: $courseId, filename: $filename) {
      signedUrl
    }
  }
`;

const videoUploadStatusQuery = gql`
  query videoUploadStatus($id: ID!) {
    course(id: $id) {
      __typename
      id
      video {
        ... on HostedVideo {
          id
          __typename
          status
          mp4Url
          hlsUrl
        }
      }
    }
  }
`;

const CreateCourseModal: React.FC<CreateCourseModalProps> = ({
  isOpen,
  channel,
  onClose,
}) => {
  const [video, setVideo] = useState<File>();
  const [, uploadVideo] = useMutation<
    UploadVideoMutation,
    UploadVideoMutationVariables
  >(uploadVideoQuery);

  const [isPolling, setIsPolling] = useState(false);
  const [mutation, createCourse] = useMutation<
    CreateCourseMutation,
    CreateCourseMutationVariables
  >(createCourseMutation);

  const [videoUploadStatusResult, executeVideoUploadStatusQuery] = useQuery<
    VideoUploadStatusQuery,
    VideoUploadStatusQueryVariables
  >({
    query: videoUploadStatusQuery,
    variables: { id: mutation.data?.createCourse?.id as string },
    pause: true,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CourseInput>();

  const handleClose = useCallback(() => {
    setIsPolling(false);
    onClose();
  }, [setIsPolling, onClose]);

  const progress = useStore((state) => state.progress);
  const uploadFile = useStore((state) => state.uploadFile);

  const [uploadState, handleUpload] = useAsyncFn(
    async (courseId: string) => {
      if (!video) {
        throw new Error("No video");
      }
      const res = await uploadVideo({ courseId, filename: video.name });
      const url = res.data?.uploadVideo.signedUrl;
      if (!url) {
        throw new Error("No url received");
      }
      await uploadFile(url, video);
    },
    [video, uploadVideo]
  );

  console.info(uploadState);

  const onSubmit = async (data: CourseInput) => {
    const response = await createCourse({ channelId: channel.id, data });
    if (response?.data?.createCourse?.id) {
      await handleUpload(response.data?.createCourse.id);
      executeVideoUploadStatusQuery();
      setIsPolling(true);
    }
  };

  const status = (videoUploadStatusResult.data?.course?.video as any)?.status;

  useEffect(() => {
    if (status === CourseStatus.Complete) {
      handleClose();
      return;
    }

    if (!videoUploadStatusResult.fetching && isPolling) {
      const id = setTimeout(
        () => executeVideoUploadStatusQuery({ requestPolicy: "network-only" }),
        5000
      );
      return () => clearTimeout(id);
    }
  }, [
    videoUploadStatusResult.fetching,
    isPolling,
    executeVideoUploadStatusQuery,
    status,
    handleClose,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Create Course</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <VStack>
              <FormControl isInvalid={!!errors.title}>
                <FormLabel htmlFor="title">Title</FormLabel>
                <Input id="title" placeholder="Title" {...register("title")} />
                <FormErrorMessage>
                  {errors.title && errors.title.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.description}>
                <FormLabel htmlFor="description">Description</FormLabel>
                <Input
                  id="description"
                  placeholder="Description"
                  {...register("description")}
                />
                <FormErrorMessage>
                  {errors.description && errors.description.message}
                </FormErrorMessage>
              </FormControl>
              <Box width="100%" py={1}>
                <input
                  type="file"
                  onChange={(event) =>
                    setVideo(
                      event.target.files ? event.target.files[0] : undefined
                    )
                  }
                  accept=".mpg,.mp4,.m4v,.mov,.m2ts,.wmv,.mxf,.mkv,.m3u8,.mpeg,.webm,.h264"
                />
              </Box>
            </VStack>
          </Box>
        </ModalBody>
        <ModalFooter flexDirection="column">
          <Flex alignSelf="flex-end">
            <Button mr={3} onClick={handleClose}>
              Close
            </Button>
            <Button
              colorScheme="brand"
              type="submit"
              isLoading={isSubmitting || isPolling}
              disabled={!video || isSubmitting || isPolling}
            >
              Create
            </Button>
          </Flex>
          {isSubmitting || isPolling ? (
            <Flex alignSelf="flex-end" mt={2}>
              <Box as="span">Status:</Box>
              <Box ml={1} as="span" fontWeight="bold">
                {status ? status : `Uploading ${progress}%`}
              </Box>
            </Flex>
          ) : (
            <Spacer />
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateCourseModal;
