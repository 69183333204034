import React from "react";
import {
  Box,
  Stack,
  useColorModeValue,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Alert,
} from "@chakra-ui/react";

type CardProps = {
  error?: null | Error;
  onClearError?: () => void;
};

const Card: React.FC<CardProps> = ({ children, error, onClearError }) => (
  <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
    <Box
      rounded={"xl"}
      bg={useColorModeValue("white", "gray.700")}
      boxShadow={"lg"}
      p={8}
    >
      {children}
      {error && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error.message}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={onClearError}
          />
        </Alert>
      )}
    </Box>
  </Stack>
);

export default Card;
