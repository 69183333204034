import React, { useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  FormControl,
  VStack,
  Input,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, gql } from "urql";
import {
  CreateLearningCenterMutation,
  CreateLearningCenterMutationVariables,
  CreateContentChannelFromLearningCenterMutation,
  CreateContentChannelFromLearningCenterMutationVariables,
  ContentChannelStatus,
} from "../graphql-types";

export type LearningCenterCreateModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

export type LearningCenterData = {
  id: string;
  name: string;
  logo?: FileList;
  backgroundImage?: FileList;
};

const CreateLearningCenter = gql`
  mutation CreateLearningCenter(
    $data: LearningCenterInput!
    $logo: Upload
    $backgroundImage: Upload
  ) {
    adminCreateLearningCenter(
      data: $data
      logo: $logo
      backgroundImage: $backgroundImage
    ) {
      id
      name
    }
  }
`;

const CreateContentChannel = gql`
  mutation CreateContentChannelFromLearningCenter(
    $data: ContentChannelInput!
    $logo: Upload
    $backgroundImage: Upload
    $owningLearningCenterId: ID
  ) {
    createContentChannel(
      data: $data
      logo: $logo
      backgroundImage: $backgroundImage
      owningLearningCenterId: $owningLearningCenterId
    ) {
      __typename
      id
      name
    }
  }
`;

const LearningCenterCreateModal: React.FC<LearningCenterCreateModalProps> = ({
  onClose,
  isOpen,
}) => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LearningCenterData>();

  const [, createLearningCenter] = useMutation<
    CreateLearningCenterMutation,
    CreateLearningCenterMutationVariables
  >(CreateLearningCenter);

  const [, createContentChannel] = useMutation<
    CreateContentChannelFromLearningCenterMutation,
    CreateContentChannelFromLearningCenterMutationVariables
  >(CreateContentChannel);

  const onSubmit = useCallback(
    async (data: LearningCenterData) => {
      const learningCenterResponse = await createLearningCenter({
        data: {
          name: data.name,
        },
        logo: data.logo?.[0],
        backgroundImage: data.backgroundImage?.[0],
      });

      console.log(learningCenterResponse)
      if (learningCenterResponse.error) {
        toast({
          title: "Failed!",
          description: learningCenterResponse.error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const channelResponse = await createContentChannel({
        data: {
          name: data.name,
          status: ContentChannelStatus.Private,
        },
        logo: data.logo?.[0],
        backgroundImage: data.backgroundImage?.[0],
        owningLearningCenterId:
          learningCenterResponse.data?.adminCreateLearningCenter?.id,
      });

      if (channelResponse.error) {
        toast({
          title: "Failed!",
          description: channelResponse.error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      toast({
        title: "Learning Center Created!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    },
    [createLearningCenter, createContentChannel, toast, onClose]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Create New Learning Center</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <VStack>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input id="name" placeholder="Name" {...register("name")} />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="logo">Logo</FormLabel>
                <InputGroup>
                  <input {...register("logo")} id="logo" type="file" />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="background">Background Image</FormLabel>
                <InputGroup>
                  <input
                    {...register("backgroundImage")}
                    id="background"
                    type="file"
                  />
                </InputGroup>
              </FormControl>
            </VStack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="brand" type="submit" isLoading={isSubmitting}>
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LearningCenterCreateModal;
