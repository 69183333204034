import React from "react";
import {
  Flex,
  IconButton,
  Text,
  Tooltip,
  Select,
  Icon,
} from "@chakra-ui/react";
import {
  FiChevronRight,
  FiChevronLeft,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";

export type TablePaginationProps = {
  pageIndex: number;
  canNextPage: boolean;
  canPreviousPage: boolean;
  pageCount: number;
  pageSize: number;
  totalCount: number;
  gotoPage: (page: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  setPageSize: (size: number) => void;
};

const TablePagination: React.FC<TablePaginationProps> = ({
  pageIndex,
  canNextPage,
  canPreviousPage,
  pageCount,
  pageSize,
  totalCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
}) => (
  <Flex justifyContent="flex-end" alignItems="center" width="100%" py={2}>
    <Flex alignItems="center" mx={2}>
      <Select
        mr={1}
        w={32}
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </Select>

      <Text ml={2}>
        Page{" "}
        <Text fontWeight="bold" as="span">
          {pageIndex + 1}
        </Text>{" "}
        of{" "}
        <Text fontWeight="bold" as="span">
          {totalCount}
        </Text>
      </Text>
    </Flex>
    <Flex>
      <Tooltip label="First Page">
        <IconButton
          aria-label="Go To First Page"
          onClick={() => gotoPage(0)}
          isDisabled={!canPreviousPage}
          icon={<Icon as={FiChevronsLeft} h={6} w={6} />}
          mx={1}
        />
      </Tooltip>
      <Tooltip label="Previous Page">
        <IconButton
          aria-label="Go To Previous Page"
          onClick={previousPage}
          isDisabled={!canPreviousPage}
          icon={<Icon as={FiChevronLeft} h={6} w={6} />}
          mx={1}
        />
      </Tooltip>
      <Tooltip label="Next Page">
        <IconButton
          aria-label="Go To Next Page"
          onClick={nextPage}
          isDisabled={!canNextPage}
          icon={<Icon as={FiChevronRight} h={6} w={6} />}
          mx={1}
        />
      </Tooltip>
      <Tooltip label="Last Page">
        <IconButton
          aria-label="Go To Last Page"
          onClick={() => gotoPage(pageCount - 1)}
          isDisabled={!canNextPage}
          icon={<Icon as={FiChevronsRight} h={6} w={6} />}
          mx={1}
        />
      </Tooltip>
    </Flex>
  </Flex>
);

export default TablePagination;
