import { Box, useColorModeValue } from "@chakra-ui/react";
import Authenticator from "./components/Authenticator";
import { Routes, Route } from "react-router-dom";
import Nav from "./components/Nav";
import LearningCenters from "./pages/LearningCenters";
import Invites from "./pages/Invites";
import Profile from "./pages/Profile";
import Channels from "./pages/Channels";
import Channel from "./pages/Channel";
import NotFound from "./pages/NotFound";

export const App = () => {
  return (
    <Authenticator>
      <Box textAlign="center" fontSize="xl">
        <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
          <Nav />
          <Box ml={{ base: 0, md: 60 }} p="4">
            <Routes>
              <Route path="/" element={<LearningCenters />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/invites" element={<Invites />} />
              <Route path="/channels" element={<Channels />} />
              <Route path="/channels/:channelId" element={<Channel />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
        </Box>
      </Box>
    </Authenticator>
  );
};
