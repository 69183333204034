import React, { useState, useCallback } from "react";
import {
  Container,
  Heading,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Spinner,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useQuery, gql } from "urql";
import { FiTrash } from "react-icons/fi";
import {
  ContentChannelQuery,
  ContentChannelQueryVariables,
  HostedVideo,
  ContentChannel
} from "../graphql-types";
import CourseCreateModal from "../components/CourseCreateModal";
import VideoModal from "../components/VideoModal";
import CourseDeleteModal, {
  DeleteCourseData,
} from "../components/CourseDeleteModal";
import ChannelEditModal from "../components/ChannelEditModal";

const contentChannelQuery = gql`
  query ContentChannel($id: ID!) {
    contentChannel(id: $id) {
      __typename
      id
      name
      courses {
        __typename
        id
        title
        video {
          ... on HostedVideo {
            __typename
            id
            mp4Url
            hlsUrl
            status
          }
        }
        views
        courseCompletionRate {
          completionRate
        }
      }
    }
  }
`;

const Channel: React.FC = () => {
  const { channelId = "" } = useParams<"channelId">();
  const [{ data, fetching }, executeChannelQuery] = useQuery<
    ContentChannelQuery,
    ContentChannelQueryVariables
  >({
    query: contentChannelQuery,
    variables: { id: channelId },
    pause: !channelId,
  });

  const {
    isOpen: isCreateCourseModalOpen,
    onClose: onCreateCourseModalClose,
    onOpen: onCreateCourseModalOpen,
  } = useDisclosure();

  const handleClose = () => {
    executeChannelQuery({ requestPolicy: "network-only" });
    onCreateCourseModalClose();
  };

  const {
    onOpen: handleDeleteModalOpen,
    onClose: handleDeleteModelClose,
    isOpen: isDeleteModalOpen,
  } = useDisclosure();

  const [course, setCourse] = useState<DeleteCourseData | null>(null);

  const handleDeleteCourse = useCallback(
    (course: DeleteCourseData) => () => {
      setCourse(course);
      handleDeleteModalOpen();
    },
    [setCourse, handleDeleteModalOpen]
  );

  const [channel, setChannel] = useState<Partial<ContentChannel> | null>(null);

  const {
    onOpen: handleEditModalOpen,
    onClose: handleEditModelClose,
    isOpen: isEditModelOpen,
  } = useDisclosure();

  const handleStopEdit = () => {
    setChannel(null);
    handleEditModelClose();
  };

  const [selectedVideo, setSelectedVideo] = useState<null | HostedVideo>(null);

  const handleVideoModalOpen = (video?: HostedVideo | null) => () =>
    video ? setSelectedVideo(video) : null;

  const handleVideoModalClose = () => setSelectedVideo(null);

  if (fetching) {
    return <Spinner />;
  }

  if (!data || !data.contentChannel || !channelId) {
    return <Flex>Not Found</Flex>;
  }

  return (
    <Container centerContent maxW="container.lg" bg="white" py="8">
      <Heading>{data.contentChannel.name}</Heading>
      <Table variant="simple" mt={6}>
        <Thead>
          <Tr>
            <Th>Title</Th>
            <Th>Upload Status</Th>
            <Th>Views</Th>
            <Th>Delete</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.contentChannel.courses &&
            data.contentChannel.courses.map((course) => (
              <Tr key={course.id}>
                <Td
                  color={
                    course.video && course.video.__typename === "HostedVideo"
                      ? "brand.600"
                      : undefined
                  }
                  onClick={
                    course.video && course.video.__typename === "HostedVideo"
                      ? handleVideoModalOpen(course.video)
                      : () => {}
                  }
                  cursor={
                    course.video && course.video.__typename === "HostedVideo"
                      ? "pointer"
                      : undefined
                  }
                >
                  {course.title}
                </Td>
                <Td>{(course.video as any).status ?? "MISSING"}</Td>
                <Td>{course.views || 0}</Td>
                <Td>
                  {" "}
                  <IconButton
                    aria-label="Delete course"
                    icon={<FiTrash />}
                    onClick={handleDeleteCourse(course)}
                  />
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <Flex pt={4} justifyContent="space-between" w="100%">
        <Button onClick={onCreateCourseModalOpen}>Create Course</Button>
        <Button onClick={handleEditModalOpen}>Edit Channel</Button>
      </Flex>
      <CourseCreateModal
        channel={data.contentChannel}
        isOpen={isCreateCourseModalOpen}
        onClose={handleClose}
      />
      <VideoModal video={selectedVideo} onClose={handleVideoModalClose} />
      <CourseDeleteModal
        onClose={handleDeleteModelClose}
        isOpen={isDeleteModalOpen}
        course={course}
      />
      <ChannelEditModal
        channel={channel}
        onClose={handleStopEdit}
        isOpen={isEditModelOpen}
      />
    </Container>
  );
};

export default Channel;
