import React, { useState, useMemo, useCallback } from "react";
import {
  Container,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Button,
  Spinner,
  IconButton,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import { FiEdit, FiTrash, FiUserPlus } from "react-icons/fi";
import { useQuery, gql } from "urql";
import {
  useTable,
  CellProps,
  Column,
  usePagination,
  useGlobalFilter,
} from "react-table";
// import LearningCenterEditModal from "../components/LearningCenterEditModal";
import LearningCenterInviteModal from "../components/LearningCenterInviteModal";
import LearningCenterCreateModal from "../components/LearningCenterCreateModal";
import LearningCenterDeleteModal from "../components/LearningCenterDeleteModal";
import TablePagination from "../components/TablePagination";
import { LearningCenter } from "../graphql-types";

export type LearningCenterData = {
  name: string;
};

const LearningCenters = gql`
  query LearningCenteres {
    learningCenters {
      id
      name
      ownedContentChannels {
        id
      }
    }
  }
`;

type LearningCenterQuery = {
  learningCenters: LearningCenter[];
};

const LearningCenterControl: React.FC = () => {
  const [learningCenter, setLearningCenter] = useState<LearningCenter | null>(
    null
  );

  const [{ data: learningCenterData, fetching }] =
    useQuery<LearningCenterQuery>({
      query: LearningCenters,
      variables: {},
    });

  const {
    onOpen: handleInviteModalOpen,
    onClose: handleInviteModelClose,
    isOpen: isInviteModelOpen,
  } = useDisclosure();

  const {
    onOpen: handleDeleteModalOpen,
    onClose: handleDeleteModelClose,
    isOpen: isDeleteModalOpen,
  } = useDisclosure();

  const {
    onOpen: handleCreateModalOpen,
    onClose: handleCreateModelClose,
    isOpen: isCreateModelOpen,
  } = useDisclosure();

  // const {
  //   onOpen: handleEditModalOpen,
  //   onClose: handleEditModelClose,
  //   isOpen: isEditModelOpen,
  // } = useDisclosure();

  // const handleEdit = useCallback(
  //   (learningCenter: LearningCenter) => () => {
  //     handleEditModalOpen();
  //     setLearningCenter(learningCenter);
  //   },
  //   [handleEditModalOpen, setLearningCenter]
  // );
  // const handleStopEdit = () => {
  //   setLearningCenter(null);
  //   handleEditModelClose();
  // };

  const handleEdit = useCallback(
    (learningCenter: Partial<LearningCenter>) => () => {
      const url = `${process.env.REACT_APP_WEB_URL}/${learningCenter.id}/dashboard`;
      window.open(url, "_blank")!.focus();
    },
    []
  );

  const handleInviteToLearningCenter = useCallback(
    (learningCenter: LearningCenter) => () => {
      setLearningCenter(learningCenter);
      handleInviteModalOpen();
    },
    [setLearningCenter, handleInviteModalOpen]
  );

  const handleDeleteLearningCenter = useCallback(
    (learningCenter: LearningCenter) => () => {
      setLearningCenter(learningCenter);
      handleDeleteModalOpen();
    },
    [setLearningCenter, handleDeleteModalOpen]
  );

  const handleStopInviteToLearningCenter = () => {
    setLearningCenter(null);
    handleInviteModelClose();
  };

  const handleStopDeleteLearningCenter = () => {
    setLearningCenter(null);
    handleDeleteModelClose();
  };

  const columns = useMemo(
    (): Column<LearningCenter>[] => [
      {
        Header: "Name",
        accessor: "name" as "name",
      },
      {
        id: "edit",
        Header: "Edit",
        Cell: ({ row }: CellProps<LearningCenter>) => (
          <IconButton
            aria-label="Edit Learning Center"
            icon={<FiEdit />}
            onClick={handleEdit(row.original)}
          />
        ),
      },
      {
        id: "invite",
        Header: "Add Admin",
        Cell: ({ row }: CellProps<LearningCenter>) => (
          <IconButton
            ml={4}
            aria-label="Add Admins"
            icon={<FiUserPlus />}
            onClick={handleInviteToLearningCenter(row.original)}
          />
        ),
      },
      {
        id: "delete",
        Header: "Delete",
        Cell: ({ row }: CellProps<LearningCenter>) => (
          <IconButton
            aria-label="Delete Learning Center"
            icon={<FiTrash />}
            onClick={handleDeleteLearningCenter(row.original)}
          />
        ),
      },
    ],
    [handleEdit, handleInviteToLearningCenter, handleDeleteLearningCenter]
  );

  const data = useMemo(
    () => (learningCenterData ? learningCenterData.learningCenters : []),
    [learningCenterData]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    page,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable({ columns, data }, useGlobalFilter, usePagination);

  if (fetching) {
    return <Spinner />;
  }

  return (
    <Container centerContent maxW="container.lg" bg="white" py="8">
      <Heading>Learning Centers</Heading>
      <Input
        mx={6}
        mt={4}
        placeholder="Search"
        onChange={(e) => setGlobalFilter(e.target.value)}
        value={globalFilter}
      />
      <Table variant="simple" {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {data.length ? (
        <TablePagination
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          totalCount={pageOptions.length}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
        />
      ) : null}
      <Flex justify="space-between" width="100%" px={2} py={4}>
        <Button onClick={handleCreateModalOpen}>Create Learning Center</Button>
        <Button onClick={handleInviteModalOpen}>
          Setup Learning Center By New User
        </Button>
      </Flex>
      {/* <LearningCenterEditModal
        user={userData?.user!}
        learningCenter={learningCenter}
        onClose={handleStopEdit}
        isOpen={isEditModelOpen}
      /> */}
      <LearningCenterInviteModal
        onClose={handleStopInviteToLearningCenter}
        isOpen={isInviteModelOpen}
        learningCenter={learningCenter}
      />
      <LearningCenterDeleteModal
        onClose={handleStopDeleteLearningCenter}
        isOpen={isDeleteModalOpen}
        learningCenter={learningCenter}
      />
      <LearningCenterCreateModal
        onClose={handleCreateModelClose}
        isOpen={isCreateModelOpen}
      />
    </Container>
  );
};

export default LearningCenterControl;
