import React, { useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, gql } from "urql";
import { LearningCenter } from "../graphql-types";

const InviteToLearningCenter = gql`
  mutation InviteToLearningCenter(
    $recipientInput: InviteRecipientInput!
    $learningCenterId: ID!
  ) {
    inviteLearningCenterOwner(
      recipientInput: $recipientInput
      learningCenterId: $learningCenterId
    ) {
      id
      status
      type
    }
  }
`;

const InviteNewLearningCenterOwner = gql`
  mutation InviteNewLearningCenterOwner(
    $recipientInput: InviteRecipientInput!
  ) {
    inviteNewLearningCenterOwner(recipientInput: $recipientInput) {
      id
      status
      type
    }
  }
`;

export type UserInvitationData = {
  firstName: string;
  lastName: string;
  email: string;
};

export type LearningCenterEditModalProps = {
  onClose: () => void;
  isOpen: boolean;
  learningCenter: LearningCenter | null;
};

const LearningCenterInviteModal: React.FC<LearningCenterEditModalProps> = ({
  onClose,
  isOpen,
  learningCenter,
}) => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UserInvitationData>();

  const [, inviteNewLearningCenterOwner] = useMutation(
    InviteNewLearningCenterOwner
  );

  const [, inviteToLearningCenter] = useMutation(InviteToLearningCenter);

  const onSubmit = useCallback(
    async ({ email, firstName, lastName }: UserInvitationData) => {
      let response;
      if (learningCenter) {
        response = await inviteToLearningCenter({
          recipientInput: {
            email,
            lastName,
            firstName,
          },
          learningCenterId: learningCenter.id,
        });
      } else {
        response = await inviteNewLearningCenterOwner({
          recipientInput: { email, firstName, lastName },
        });
      }

      if (response.error) {
        toast({
          title: "Failed!",
          description: response.error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Invite Sent",
          description: `We've invited ${firstName} ${lastName}`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [
      learningCenter,
      toast,
      inviteNewLearningCenterOwner,
      inviteToLearningCenter,
    ]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          {learningCenter
            ? `Invite Admin to ${learningCenter.name}`
            : "Invite New Admin to create a new Learning Center"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <FormControl isInvalid={!!errors.firstName}>
              <FormLabel htmlFor="firstName">First Name</FormLabel>
              <Input
                id="firstName"
                placeholder="First Name"
                {...register("firstName")}
              />
              <FormErrorMessage>
                {errors.firstName && errors.firstName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.lastName}>
              <FormLabel htmlFor="lastName">Last Name</FormLabel>
              <Input
                id="lastName"
                placeholder="Last Name"
                {...register("lastName")}
              />
              <FormErrorMessage>
                {errors.lastName && errors.lastName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.email}>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                type="email"
                placeholder="Email"
                {...register("email")}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="brand" type="submit" isLoading={isSubmitting}>
            Invite
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LearningCenterInviteModal;
