import Auth, { CognitoUser } from "@aws-amplify/auth";

Auth.configure({
  region: process.env.REACT_APP_COGNITO_REGION,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
});

export const signUp = async (email: string, password: string) => {
  return Auth.signUp({
    username: email,
    password,
    attributes: {
      email,
    },
  });
};

export const confirmSignUp = async (email: string, code: string) => {
  return Auth.confirmSignUp(email, code);
};

export const signIn = async (email: string, password: string) => {
  return Auth.signIn(email, password);
};

export const resendConfirmationCode = async (email: string) => {
  return Auth.resendSignUp(email);
};

export const signOut = async () => {
  return Auth.signOut();
};

export const forgotPassword = async (email: string) => {
  return Auth.forgotPassword(email);
};

export const getCurrentUser = async () => {
  return Auth.currentAuthenticatedUser() as Promise<CognitoUser>;
};

export const completeNewPassword = async (
  user: CognitoUser,
  newPassword: string
) => {
  return Auth.completeNewPassword(user, newPassword);
};

export const getSession = async () => {
  return Auth.currentSession();
};
