import React, { useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  FormControl,
  VStack,
  Input,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, gql } from "urql";
import {
  CreateContentChannelMutation,
  CreateContentChannelMutationVariables,
} from "../graphql-types";

export type ChannelCreateModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

export type ChannelData = {
  id: string;
  name: string;
  logo?: FileList;
  backgroundImage?: FileList;
};

const createContentChannelMutation = gql`
  mutation CreateContentChannel(
    $data: ContentChannelInput!
    $logo: Upload
    $backgroundImage: Upload
  ) {
    createContentChannel(
      data: $data

      logo: $logo
      backgroundImage: $backgroundImage
    ) {
      __typename
      id
      name
    }
  }
`;

const ChannelCreateModal: React.FC<ChannelCreateModalProps> = ({
  onClose,
  isOpen,
}) => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ChannelData>();

  const [, createContentChannel] = useMutation<
    CreateContentChannelMutation,
    CreateContentChannelMutationVariables
  >(createContentChannelMutation);

  const onSubmit = useCallback(
    async (data: ChannelData) => {
      const response = await createContentChannel({
        data: {
          name: data.name,
        },
        logo: data.logo?.[0],
        backgroundImage: data.backgroundImage?.[0],
      });
      if (response.error) {
        toast({
          title: "Failed!",
          description: response.error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Content Channel Created!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      onClose();
    },
    [createContentChannel, toast, onClose]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Create Content Channel</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <VStack>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input id="name" placeholder="Name" {...register("name")} />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="logo">Logo</FormLabel>
                <InputGroup>
                  <input {...register("logo")} id="logo" type="file" />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="background">Background Image</FormLabel>
                <InputGroup>
                  <input
                    {...register("backgroundImage")}
                    id="background"
                    type="file"
                  />
                </InputGroup>
              </FormControl>
            </VStack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="brand" type="submit" isLoading={isSubmitting}>
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChannelCreateModal;
