import React, { useCallback, useState } from "react";
import Bluebird from "bluebird";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { LearningCenter } from "../graphql-types";
import { gql, useMutation } from "urql";

const DeleteLearningCenter = gql`
  mutation DeleteLearningCenter($id: ID!) {
    deleteLearningCenter(id: $id) {
      id
    }
  }
`;

const DeleteContentChannel = gql`
  mutation DeleteContentChannel($id: ID!) {
    deleteContentChannel(id: $id) {
      id
    }
  }
`;

export type LearningCenterDeleteModalProps = {
  onClose: () => void;
  isOpen: boolean;
  learningCenter: LearningCenter | null;
};

const LearningCenterDeleteModal: React.FC<LearningCenterDeleteModalProps> = ({
  onClose,
  isOpen,
  learningCenter,
}) => {
  const toast = useToast();
  const [shouldDeleteOwnedChannels, setShouldDeleteOwnedChannels] =
    useState(true);

  const [, deleteLearningCenter] = useMutation(DeleteLearningCenter);
  const [, deleteContentChannel] = useMutation(DeleteContentChannel);

  const onClick = useCallback(async () => {
    const response = await deleteLearningCenter({
      id: learningCenter?.id,
    });

    if (shouldDeleteOwnedChannels) {
      await Bluebird.map(
        learningCenter?.ownedContentChannels || [],
        (channel) =>
          deleteContentChannel({
            id: channel.id,
          })
      );
    }

    if (response.error) {
      toast({
        title: "Failed!",
        description: response.error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Learning Center Deleted",
        description: `${learningCenter?.name} was deleted`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    }
  }, [
    learningCenter,
    toast,
    deleteLearningCenter,
    deleteContentChannel,
    shouldDeleteOwnedChannels,
    onClose,
  ]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Delete Learning Center {learningCenter?.name}?
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>{"This cannot be undone."}</Flex>
            {!isEmpty(learningCenter?.ownedContentChannels) && (
              <Flex mt={2}>
                <Checkbox
                  colorScheme="red"
                  isChecked={shouldDeleteOwnedChannels}
                  onChange={(e) =>
                    setShouldDeleteOwnedChannels(e.target.checked)
                  }
                >
                  Delete owned Content Channels?
                </Checkbox>
              </Flex>
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="red" onClick={onClick}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LearningCenterDeleteModal;
