import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  colors: {
    brand: {
      50: "#dcfdff",
      100: "#b5f2f8",
      200: "#8be7f1",
      300: "#61ddeb",
      400: "#3bd4e4",
      500: "#24baca",
      600: "#14919e",
      700: "#066872",
      800: "#003f46",
      900: "#00171a",
    },
  },
});

export default theme;
