import { useEffect, useCallback } from "react";
import create from "zustand";
import { CognitoUser } from "@aws-amplify/auth";
import { getCurrentUser, signOut } from "../lib/auth";

enum Status {
  IDLE = "idle",
  LOADING = "loading",
  ERROR = "error",
  SUCCESS = "success",
}

type AuthStore = {
  user: CognitoUser | null;
  status: Status;
  setUser: (user: CognitoUser | null) => void;
  setStatus: (status: Status) => void;
};

const useStore = create<AuthStore>((set) => ({
  user: null,
  setUser: (user: CognitoUser | null) => set(() => ({ user })),
  status: Status.IDLE,
  setStatus: (status: Status) => set(() => ({ status })),
}));

type UseAuth = {
  isIdle: boolean;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  isError: boolean;
  user: CognitoUser | null;
  setUser: (user: CognitoUser | null) => void;
  signOut: () => void;
};

const useAuth = (): UseAuth => {
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  const status = useStore((state) => state.status);
  const setStatus = useStore((state) => state.setStatus);

  useEffect(() => {
    (async () => {
      try {
        if (status === Status.IDLE) {
          setStatus(Status.LOADING);
          const user = await getCurrentUser();
          setUser(user);
          setStatus(Status.SUCCESS);
        }
      } catch (e) {
        setStatus(Status.SUCCESS);
        console.log(e);
        setUser(null);
      }
    })();
  }, [status, setUser, setStatus]);

  const handleSignOut = useCallback(async () => {
    await signOut();
    setUser(null);
  }, [setUser]);

  return {
    user,
    setUser,
    signOut: handleSignOut,
    isIdle: status === Status.IDLE,
    isAuthenticating: status === Status.LOADING,
    isError: status === Status.ERROR,
    isAuthenticated: !!user,
  };
};

export default useAuth;
