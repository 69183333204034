import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import ReactPlayer from "react-player";
import { HostedVideo } from "../graphql-types";

export type VideoModalProps = {
  onClose: () => void;
  video: HostedVideo | null;
};

const createUrl = (video: HostedVideo | null) => {
  if (!video || !video.hlsUrl || !video.mp4Url) {
    return null;
  }
  if (ReactPlayer.canPlay(video.hlsUrl)) {
    return video.hlsUrl;
  }
  return video.mp4Url;
};

const isHLS = (video: HostedVideo | null) =>
  !!video && !!video.hlsUrl && ReactPlayer.canPlay(video.hlsUrl);

const VideoModal: React.FC<VideoModalProps> = ({ onClose, video }) => {
  const url = createUrl(video);
  return (
    <Modal isOpen={!!video} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent background="transparent" boxShadow="none">
        <ModalCloseButton color="white" marginRight={2.5} zIndex={999} />
        <ModalBody
          justifyContent="center"
          alignItems="center"
          background="transaprent"
          boxShadow="none"
          display="flex"
        >
          {url && (
            <ReactPlayer
              url={url}
              controls
              width="100%"
              height="100%"
              config={{ file: { forceHLS: isHLS(video) } }}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default VideoModal;
