import React, { useMemo } from "react";
import {
  Container,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Input,
} from "@chakra-ui/react";
import { useQuery, gql } from "urql";
import { useTable, Column, usePagination, useGlobalFilter } from "react-table";
import useUser from "../hooks/useUser";
import { QueryInvitesArgs, Query, Invite } from "../graphql-types";
import TablePagination from "../components/TablePagination";

export type InviteData = {
  name: string;
};

const Invites = gql`
  query Invites {
    invites {
      id
      recipient {
        email
        firstName
        lastName
      }
      status
      type
    }
  }
`;

const InvitesControl: React.FC = () => {
  const [{ data: userData, fetching: isLoadingUser }] = useUser();

  const [{ data: invitesData, fetching }] = useQuery<
    { invites: Query["invites"] },
    QueryInvitesArgs
  >({
    query: Invites,
    pause: !userData?.user.id,
  });

  const columns = useMemo(
    (): Column<Invite>[] => [
      {
        Header: "Name",
        accessor: (row) => row.recipient?.email ?? "",
      },
      {
        Header: "Type",
        accessor: "type" as "type",
      },
      {
        Header: "Status",
        accessor: "status" as "status",
      },
    ],
    []
  );

  const data = useMemo(
    () => (invitesData ? invitesData.invites : []),
    [invitesData]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    page,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable({ columns, data }, useGlobalFilter, usePagination);

  if (fetching || isLoadingUser) {
    return <Spinner />;
  }

  return (
    <Container centerContent maxW="container.xl" bg="white" py="8">
      <Heading>Invites</Heading>
      {data.length ? (
        <Input
          mx={6}
          mt={4}
          placeholder="Search"
          onChange={(e) => setGlobalFilter(e.target.value)}
          value={globalFilter}
        />
      ) : null}
      <Table variant="simple" {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {data.length ? (
        <TablePagination
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          totalCount={pageOptions.length}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
        />
      ) : null}
    </Container>
  );
};

export default InvitesControl;
