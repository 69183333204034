import React, { useEffect, useCallback } from "react";
import { pick } from "lodash";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  FormControl,
  VStack,
  Input,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, gql } from "urql";
import { ContentChannel } from "../graphql-types";

export type ChannelEditModalProps = {
  onClose: () => void;
  channel: null | Partial<ContentChannel>;
  isOpen: boolean;
};

export type ChannelData = {
  id: string;
  name: string;
  logo?: FileList;
  backgroundImage?: FileList;
};

const UpdateChannel = gql`
  mutation UpdateChannelMutation(
    $id: ID!
    $data: ContentChannelInput!
    $logo: Upload
    $backgroundImage: Upload
  ) {
    updateContentChannel(
      id: $id
      data: $data
      logo: $logo
      backgroundImage: $backgroundImage
    ) {
      id
      name
    }
  }
`;

const ChannelEditModal: React.FC<ChannelEditModalProps> = ({
  onClose,
  channel,
  isOpen,
}) => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<ChannelData>();

  useEffect(() => {
    if (channel) {
      reset(pick(channel, ["id", "name"]));
    }
  }, [reset, channel]);

  const [, updateChannel] = useMutation(UpdateChannel);

  const onSubmit = useCallback(
    async (data: ChannelData) => {
      const response = await updateChannel({
        id: channel?.id,
        data: {
          name: data.name,
        },
        logo: data.logo?.[0],
        backgroundImage: data.backgroundImage?.[0],
      });
      if (response.error) {
        toast({
          title: "Failed!",
          description: response.error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Channel Updated!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      onClose();
    },
    [updateChannel, toast, channel, onClose]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Edit Content Channel</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <VStack>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input id="name" placeholder="Name" {...register("name")} />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="logo">Logo</FormLabel>
                <InputGroup>
                  <input id="logo" type="file" {...register("logo")} />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="background">Background Image</FormLabel>
                <InputGroup>
                  <input
                    id="background"
                    type="file"
                    {...register("backgroundImage")}
                  />
                </InputGroup>
              </FormControl>
            </VStack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="brand" type="submit" isLoading={isSubmitting}>
            Edit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChannelEditModal;
