import { createClient, dedupExchange, cacheExchange } from "urql";
import { authExchange } from "@urql/exchange-auth";
import { makeOperation } from "@urql/core";
import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { getSession } from "../lib/auth";

const client = createClient({
  url: process.env.REACT_APP_API_URL ?? "",
  exchanges: [
    dedupExchange,
    cacheExchange,
    authExchange<{
      token: string;
      session: CognitoUserSession;
      refreshToken: string;
    }>({
      getAuth: async ({ authState }) => {
        if (!authState || !authState.session.isValid()) {
          try {
            const session = await getSession();
            const token = session.getAccessToken().getJwtToken();
            const refreshToken = session.getRefreshToken().getToken();
            if (token && refreshToken) {
              return { token, refreshToken, session };
            }
            return null;
          } catch (err: any) {
            if (typeof err === "string" && err.includes("No current user")) {
              return null;
            }
            throw err;
          }
        }

        return null;
      },
      addAuthToOperation: ({ authState, operation }) => {
        if (!authState || !authState.token) {
          return operation;
        }

        const fetchOptions =
          typeof operation.context.fetchOptions === "function"
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};
        return makeOperation(operation.kind, operation, {
          ...operation.context,
          fetchOptions: {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              Authorization: `Bearer ${authState.token}`,
            },
          },
        });
      },
      didAuthError: ({ error }) => {
        return (
          error.response?.status === 401 ||
          (!!error.message && error.message.includes("Not authorized"))
        );
      },
      willAuthError: ({ authState }) => {
        if (!authState || !authState.session) {
          return true;
        }
        return !authState.session.isValid();
      },
    }),
    multipartFetchExchange,
  ],
});

export default client;
